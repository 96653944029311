import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components';

const Title = styled.span`
font-size: 1em;
color: #051220;
font-weight: 600;
margin-bottom: 1.4em;
`

const Description = styled.span`
font-size: 1.4em;
color: #A1A5AA;
font-weight: 600;
margin-bottom: 0.4em;
`

const ContainerLeft = styled.div`
width: 30%;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
  margin-bottom: 2em;
}
`
const ContainerRight = styled.div`
width: 50%;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
}
`

const MainContainer = styled.div`
max-width: 89%;
margin: auto;
margin-top: 6em;
margin-bottom: 6em;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
`

const Image = styled.img`
width: 100%;
`

const TextBlock2col = props => (

	    <MainContainer>
	    	<ContainerLeft>
		    		<Fade bottom duration={450} delay={300} distance="1.4em" ssrFadeout>
		    			<Description><Title>{props.title}</Title>{props.description}</Description>
		    		</Fade>
			</ContainerLeft>
			<ContainerRight>
				<Fade bottom duration={450} delay={400} distance="1.4em" ssrFadeout>
		    			<Image src={props.image} />
		    	</Fade>
			</ContainerRight>
		</MainContainer>

)

export default TextBlock2col