import React, { Component } from 'react'
import styled, { keyframes }  from 'styled-components'
import livestormAccessRoomData from '../../livestormAccessRoomData.json'
import HeroImage from '../components/heroImage'
import LargeImage from '../components/largeImage'
import LargeImage2 from '../components/largeImage'
import HeroCaseStudy from '../components/heroCaseStudy'
import TextBlockCentered from '../components/textBlockCentered'
import TextBlockCenteredBullet from '../components/textBlockCenteredBullet'
import TextBlockRight from '../components/textBlockRight'
import TextBlock2col from '../components/textBlock-2col.js'
import TextBlockImage from '../components/textBlock-image.js'
import BigTitleCaseStudy from '../components/bigTitleCaseStudy.js'
import IntroCaseStudy3 from '../components/introCaseStudy-3.js'
import CustomUI from '../images/Custom-UI.png'
import CardProject from '../components/cardProject'
import FooterCaseStudyBack from '../components/footerCaseStudyBack';

import Layout from "../components/layout"

const slideUp = keyframes`
  0% {
      transform: translateY(-50px)
  }
  100% {
      transform: translateY(0px)
  }
`

const SectionHeroCaseStudy = styled.div`
width: 100%;

  & before {
  content:'';
  height: 0vh;
  width: 100vw;
  background-color: #0065ff;
  position:absolute;
  bottom:0;
  z-index: 100;  
  animation: ${slideUp} 1s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);
  }
`
const SectionIntroCaseStudy = styled.div`
width: 100%;
`
const SectionTextBlockCentered = styled.div`
width: 100%;
`
const TitlePartContainer = styled.h1`
max-width: 80%;
padding: 3em 0em 0em 0em;
margin: auto;
`
const TitlePart = styled.div`
font-size: 1.6em;
`


export default class Livestorm extends Component {
	render () {
		return (
		<Layout>
		<div>
			<SectionHeroCaseStudy>
				{livestormAccessRoomData.heroCaseStudy.map(cell => (
	  		 		 <HeroCaseStudy title={cell.title} 
	  		 		 subtitle={cell.subtitle}
	  		 		 image={cell.image}/>
	 			))}
 			</SectionHeroCaseStudy>
			

			<SectionIntroCaseStudy>
				{livestormAccessRoomData.introCaseStudy3.map(cell => (
	  		 		 <IntroCaseStudy3 title={cell.title} 
	  		 		 description={cell.description} 
	  		 		 titleRight1={cell.titleRight1} 
	  		 		 descriptionRight1={cell.descriptionRight1}
	  		 		 titleRight2={cell.titleRight2} 
	  		 		 descriptionRight2={cell.descriptionRight2}
	  		 		 titleRight3={cell.titleRight3} 
	  		 		 descriptionRight3={cell.descriptionRight3}
	  		 		 />
	 			))}
 			</SectionIntroCaseStudy>
			
			{livestormAccessRoomData.heroImage.map(cell => (
 				<HeroImage  image={cell.image}></HeroImage>
 			))}
 			
			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

			 {livestormAccessRoomData.largeImage.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered2.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>


			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered3.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

			 {livestormAccessRoomData.largeImage1.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	


			{livestormAccessRoomData.bigTitleCaseStudy.map(cell => (
	  		 	 <BigTitleCaseStudy 
	  		 	 	title={cell.title} 
	  		 		subtitle={cell.subtitle}
	  		 		image={cell.image}
	  		 	 />
	 		))}
	
			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered4.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

 			{livestormAccessRoomData.largeImage2.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			{livestormAccessRoomData.largeImage3.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered5.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>


 			{livestormAccessRoomData.largeImage3b.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			 {livestormAccessRoomData.largeImage3c.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered6.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered7.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

 			{livestormAccessRoomData.largeImage4.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			{livestormAccessRoomData.largeImage4b.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered8.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

 			{livestormAccessRoomData.largeImage4c.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			{livestormAccessRoomData.largeImage4d.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}

 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered9.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered10.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>
		
		
			{livestormAccessRoomData.largeImage6.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered11.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>


 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered12.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>


			{livestormAccessRoomData.largeImage8.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}

 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered13.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>



			{livestormAccessRoomData.largeImage9.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered14.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>

			{livestormAccessRoomData.largeImage10.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			{livestormAccessRoomData.largeImage11.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			<SectionTextBlockCentered>
					{livestormAccessRoomData.textBlockCentered15.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
			</SectionTextBlockCentered>
			
			<FooterCaseStudyBack />
		</div>
		</Layout>
		)
	}
}